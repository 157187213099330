import GlobalLayout from "components/page/GlobalLayout";
import { cloudSpecs, onPremSpecs } from "components/vs/lib/comparisonData";
import VsPageUpdated from "components/vs/updated/VsPageUpdated";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function RhombusVsVerkada() {
  const renderContent = data => {
    const pageData = {
      header: {
        title: "Say Goodbye to Outdated NVRs and DVRs",
        paragraph:
          "Limitless scalability, secure remote access, and an easy-to-use web console — see why leading organizations are upgrading to Rhombus.",
        formId: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
        image: data.headerImage,
        mobileImage: data.headerImageMobile,
        logos: [
          {
            img: data.logo1,
            width: "130px",
            alt: "Hurley",
          },
          {
            img: data.logo2,
            width: "177px",
            alt: "Aramark",
          },
          {
            img: data.logo3,
            width: "115px",
            alt: "Stryker",
          },
          {
            img: data.logo4,
            width: "186px ",
            alt: "Los Angeles Film School",
          },
          {
            img: data.logo5,
            width: "149px",
            alt: "Goodwill",
          },
          {
            img: data.logo6,
            width: "137px",
            alt: "Clark Construction",
          },
        ],
      },

      benefits: {
        title: "What You Can Expect from Rhombus",
        list: [
          {
            tabTitle: "infinite Scalability",
            preTitle: "UNLIMITED POSSILITIES",
            title: "A Platform that Grows with Your Organization",
            description:
              "Fast and easy deployment anywhere in the world. Manage unlimited devices, locations, and users from a single console. From 10 to 1,000+ locations, Rhombus is there to scale with your needs.",
            image: data.benefit1,
          },
          {
            tabTitle: "Unified Platform",
            preTitle: "Best-in-class performance, reliability, and analytics",
            title: "Powerful Technology on a Unified Cloud Platform",
            description:
              "Rhombus is the central nervous system for physical security management. Easily increase visibility and proactively detect threats. Stay up to date with automatic updates with cutting-edge features at no additional cost.",
            image: data.benefit2,
          },
          {
            tabTitle: "Secure-by-Default",
            preTitle: "Security first with the track record to prove it",
            title: "Data Privacy is in Our DNA",
            description:
              "Founded by cybersecurity experts, Rhombus is built from the ground up to be secure by default. While comparable companies have suffered serious breaches, we safeguard your data so you can enjoy complete security.",
            image: data.benefit3,
          },
        ],
      },
      features: [
        {
          icon: data.icon1,
          iconAlt: "User-Friendly",
          title: "User-Friendly",
          p:
            "Rhombus combines consumer-level ease of use with enterprise-grade performance, no technical expertise required.",
        },
        {
          icon: data.icon2,
          iconAlt: "AI Analytics",
          title: "AI Analytics",
          p:
            "Rhombus AI uses machine learning to save time, accelerate investigations, and send real-time intelligent alerts.",
        },
        {
          icon: data.icon3,
          iconAlt: "24/7 Remote Access",
          title: "24/7 Remote Access",
          p:
            "Access live video feeds and receive real-time alerts from a web-based console or mobile app.",
        },
        {
          icon: data.icon4,
          iconAlt: "Infinite Scalability",
          title: "Infinite Scalability",
          p:
            "Scale geographically and vertically within your organization with unlimited devices, locations, and users.",
        },
      ],
      compareTable: {
        title:
          "Rhombus’s Platform Provides Ultimate Flexibility and Best-in-Class Performance",
        rhombus: cloudSpecs,
        competitor: {
          data: onPremSpecs,
          title: "On-Prem",
        },
        keys: [
          "No NVR/DVRs, servers, or hard drives",
          "Secure remote access",
          "Straightforward deployment",
          "Unlimited scalability across multiple locations",
          "Intuitive software",
          "Automatic firmware updates",
          "New features at no cost",
          "Customizable user permissions",
        ],
      },
      customerStories: {
        title:
          "Industry Leaders are Switching to Rhombus as their Cloud Security",
        cards: [
          {
            img: data.story1,
            logo: {
              img: data.cslogo1,
              width: "100px",
              alt: "Aids Healthcare Foundation",
            },
            quote:
              "“The quality of the Rhombus cameras and overall system is simply outstanding. It’s robust, flexible, and easy to install—the perfect technology to deploy across our clinics.”",
            link: "/case-studies/aids-healthcare-foundation/",
          },
          {
            img: data.story2,
            logo: {
              img: data.cslogo2,
              width: "95px ",
              alt: "Nemaha School District",
            },
            quote:
              "“Having remote access aligns with how organizations operate in today’s world. The Rhombus mobile app makes it easy for me and my staff to log-in and check if everything is okay across multiple buildings.”",
            link: "/case-studies/nemaha-school-district/",
          },
          {
            img: data.story3,
            logo: {
              img: data.cslogo3,
              width: "75px",
              alt: "10 Federal Storage",
            },
            quote:
              "“Rhombus has allowed us to remotely view any property, be alerted to specific activities, better serve our customers, actively control building access, and more. We’re big fans, to say the least.”",
            link: "/case-studies/10-federal/",
          },
        ],
      },
    };

    return (
      <GlobalLayout color="var(--gradient-light)" landingPage>
        <Helmet>
          <title>Rhombus vs On-Premise Video Surveillance Systems</title>
          <meta
            name="description"
            content="Learn why cloud-native physical security is better than traditional NVR systems. See why school districts, commercial businesses, cities, and more use Rhombus."
          />
        </Helmet>
        <VsPageUpdated data={pageData} g2Section />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/vs/updated/img/trad-vs-cloud-hero-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      headerImageMobile: file(
        relativePath: {
          eq: "components/vs/updated/img/trad-vs-cloud-hero-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      logo1: file(
        relativePath: { eq: "components/common/logos/hurley-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(
        relativePath: { eq: "components/common/logos/aramark-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: { eq: "components/common/logos/stryker-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: { eq: "components/common/logos/lafs-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/common/logos/goodwill-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/common/logos/clark-construction-white.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      benefit1: file(
        relativePath: {
          eq: "components/vs/updated/img/infinite-scalability-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit2: file(
        relativePath: {
          eq: "components/vs/updated/img/unified-platform-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit3: file(
        relativePath: { eq: "components/vs/updated/img/data-privacy-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit4: file(
        relativePath: { eq: "components/demo/img/easy-investigations-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/system.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/learning.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/industries/img/icons/laptop.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/industries/img/icons/location.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      competitorLogo: file(
        relativePath: { eq: "components/vs/updated/img/Verkada.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      cslogo1: file(
        relativePath: { eq: "components/common/logos/ahf-white-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      cslogo2: file(
        relativePath: { eq: "components/common/logos/nsd-white-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      cslogo3: file(
        relativePath: {
          eq: "components/common/logos/10-federal-white-logo.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      story1: file(
        relativePath: { eq: "components/vs/updated/img/ahf-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      story2: file(
        relativePath: { eq: "components/vs/updated/img/nemaha-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      story3: file(
        relativePath: { eq: "components/vs/updated/img/10-federal-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
